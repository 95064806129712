import React, { useState, useEffect } from 'react';

import Logo from "../../assets/logo/Logo-Trans.png";
import image from "../../assets/logo/auth.png";
import { Link } from "react-router-dom";
import GifComponent from "./GifComponent";

export default function WelcomeAuth() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulating a delay to represent website loading
    setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Change the duration as needed
  }, []);
  return (
    <>


    
    

      {isLoading ? (
<GifComponent/>
      
      ) : (
        // Render your website content here
        <div className="lg:overflow-hidden">
        <div className="flex flex-row justify-start  ">
          <div className="item-1 h-screen lg:w-full bg-[#cb22290f] ">
            <div className="flex flex-col justify-center items-center mt-12 gap-[12pxs]">
              <img
                src={Logo}
                alt=""
                className="lg:w-[134pxl.] bounce-in-top  "
              />
            </div>
            <div className="text">
              <h1 className="text-center font-all font-extrabold text-[35px] leading-[70px] text-[#000000] ">
                Welcome to INO !
              </h1>
            </div>
            <div className="image relative top-[14px] drop-auth mx-auto w-[23%] bg-white rounded-[33px]">
              <img
                src={image}
                className="mx-ato m-auto h-[21rem] image-coin  "
                alt=""
              />
            </div>

            <p className="font-all w-[55rem] mx-auto mt-[3rem]">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta
              corrupti inventore voluptatum molestias culpa quam facere,
              deserunt fugiat nisi consequuntur earum quisquam consequatur
              saepe.
            </p>

            <p className="font-all mt-5 text-center">
              Tap 'Agree & Continue' to procceed after reveiwing the INO
              <Link to="/auth/to">
                <span
                  className="font-all underline-offset-2 text-[#cb2229] cursor-pointer"
                  style={{ textDecoration: "underline" }}
                >
                  Terms of Services
                </span>
              </Link>
            </p>
<Link  to="/auth/verifyphone" >
            <button class="flex justify-center fade-in-left bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[2.4rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out mx-auto">
              Agree and Continue
            </button>
            </Link>
          </div>
        </div>
      </div>

      )}
    </>
  );
}
